import React, {Fragment, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faBurger, faContactBook, faCrown, faDiamond, faFolder, faGem, faJournalWhills, faLanguage, faMedal, faShop, faShoppingBag, faSigning, faSignOut, faTachometerAlt, faTimes, faTrophy, faUserTie} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory, useLocation} from "react-router-dom";
import CartItemStoreBubble from "./CartItemStoreBubble";
import {useDispatch, useSelector} from "react-redux";
import CartItemUpdateBubble from "./CartItemUpdateBubble";
import CartItemDestroyBubble from "./CartItemDestroyBubble";
import {motion} from "framer-motion";
import {SpinnerRoundOutlined} from "spinners-react";
import {ListActiveOrders} from "../redux/checkout/actionCreator";
import {AnimatePresence} from "framer-motion";
import {SpinnerCircular} from "spinners-react";
import CartBadge from "./CartBadge";
import LanguageBar from "./LanguageBar";
import T from "../components/T";
import Can from './Can';
import {DashboardSwitchVisibility, HideDashboardMenu, HideHeader, ShowDashboardMenu, ShowHeader} from "../redux/settings/actionCreator";

const Header = () => {

    const cart_items = useSelector(state => state.cart.cart_items);
    const bubble = useSelector(state => state.cart.store_bubble);
    const location = useLocation();
    const menuRoute = location.pathname === "/menu";
    const dashboardRoute = location.pathname.includes("/dashboard");
    const cartRoute = location.pathname === "/cart";
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const loading = useSelector(state => state.menu.index_loading);
    const error = useSelector(state => state.menu.index_error);
    const user = useSelector(state => state.auth.user);
    const lang = useSelector(state => state.settings.lang);

    let branch_guid = localStorage.getItem('branch_guid');

    const v1_loading = useSelector(state => state.checkout.validate_loading);
    const v2_loading = useSelector(state => state.checkout.active_orders);
    const v_loading = v1_loading || v2_loading;

    const total_active_orders = useSelector(state => state.checkout.total_active_orders);
    const active_orders_loading = useSelector(state => state.checkout.orders_loading);
    const active_orders = useSelector(state => state.checkout.active_orders);

    const [visible, setVisible] = useState(false);
    const [dashboardMenuVisible, setDashboardMenuVisible] = useState(false);
    const _dashboardMenuVisible = useSelector(state => state.settings.dashboard_menu_visible);

    const history = useHistory();
    const ref = useRef(null);
    const barRef = useRef(null);
    const device_uuid = localStorage.getItem('device_uuid');
    const order_uids = JSON.parse(localStorage.getItem('order_uids')) || [];

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && barRef.current && !barRef.current.contains(event.target)) {
            close();
        }
    };

    const updateActiveOrders = () => {
        dispatch(ListActiveOrders());
    }

    let timer = null;

    useEffect(() => {
        setDashboardMenuVisible(_dashboardMenuVisible);
    }, [_dashboardMenuVisible]);

    useEffect(() => {
        if (active_orders && active_orders.length > 0 && !active_orders_loading && !timer) {
            timer = setTimeout(updateActiveOrders, 1000 * 60 * 5);
        }
    }, [active_orders]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    let userChars = '';
    if (user) {
        userChars = user.name[0].toUpperCase();
    }

    const redirectToLogin = () => {
        history.push("/login", {state: {redirect_pathname: history.location.pathname}});
    }

    const open = () => {
        if(dashboardRoute) {
            setDashboardMenuVisible(true);
            dispatch(ShowDashboardMenu());
        }
        else {
            setVisible(true);
            dispatch(ShowHeader());
        }
    }

    const close = () => {
        if(dashboardRoute) {
            setDashboardMenuVisible(false);
            dispatch(HideDashboardMenu());
        }
        else {
            setVisible(false);
            dispatch(HideHeader());
        }
    }

    const dashboardIconClick = (e) => {
        if (location.pathname.startsWith("/dashboard/")) {
            dispatch(DashboardSwitchVisibility());
            e.preventDefault();
            return false;
        }
    }

    return (
        !global.config.underConstructions && !location.pathname.includes("/print/") &&
        <div as="nav" className="fixed w-full z-50" style={{zIndex: 1000}}>
            <>
                <div className="px-4 sm:px-6 lg:px-8 relative" style={{zIndex: 30, backgroundColor: "#e71d29", borderBottom: "5px solid black"}}>

                    <div className={"hidden"}>
                        <div className="w-6 h-6 items-center justify-center rounded-full text-white bg-black top-5 right-2 z-50 fixed hidden xxs:flex xs:hidden">XXS</div>
                        <div className="w-6 h-6 items-center justify-center rounded-full text-white bg-black top-5 right-2 z-50 fixed hidden xs:flex sm:hidden">XS</div>
                        <div className="w-6 h-6 items-center justify-center rounded-full text-white bg-black top-5 right-2 z-50 fixed hidden sm:flex md:hidden">SM</div>
                        <div className="w-6 h-6 items-center justify-center rounded-full text-white bg-black top-5 right-2 z-50 fixed hidden md:flex lg:hidden">MD</div>
                        <div className="w-6 h-6 items-center justify-center rounded-full text-white bg-black top-5 right-2 z-50 fixed hidden lg:flex xl:hidden">LG</div>
                        <div className="w-6 h-6 items-center justify-center rounded-full text-white bg-black top-5 right-2 z-50 fixed hidden xl:flex 2xl:hidden">XL</div>
                        <div className="w-6 h-6 items-center justify-center rounded-full text-white bg-black top-5 right-2 z-50 fixed hidden 2xl:flex 3xl:hidden">2XL</div>
                        <div className="w-6 h-6 items-center justify-center rounded-full text-white bg-black top-5 right-2 z-50 fixed hidden 3xl:flex">3XL</div>
                    </div>
                    <CartItemStoreBubble/>
                    <CartItemUpdateBubble/>
                    <CartItemDestroyBubble/>

                    <div className="flex items-center justify-between h-16">

                        <div className="-mr-2 flex md:hidden">
                            <div className="inline-flex items-center justify-center p-2 rounded-md text-black cursor-pointer w-12">
                                {visible || dashboardMenuVisible ? (
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/close.png'}
                                        onClick={close}
                                    />
                                ) : (
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/bars.png'}
                                        onClick={open}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="flex items-center md:flex-1">
                            <div className="flex-shrink-0 flex flex-row items-center">
                                <Link to={{pathname: "/"}}>
                                    <img
                                        className="h-12"
                                        src={process.env.PUBLIC_URL + '/images/logo-small.png'}
                                        alt="Patty Slaps"
                                    />
                                </Link>

                                {!dashboardRoute &&
                                    <div className={"ml-6 flex-row items-center text-base hidden md:flex"}>
                                        <div className="mx-2">
                                            <Link to={"/about"} className="text-white uppercase font-bold text-with-border">
                                                {lang == 'fr'?'À Propos':'About'}
                                            </Link>
                                        </div>
                                        <div className="mx-2">
                                            <Link to={"/contact"} className="text-white uppercase font-bold text-with-border">
                                                contact
                                            </Link>
                                        </div>

                                        <div className="mx-2">
                                            <a href={"https://careers.pattyslaps.com/"} className="text-white uppercase font-bold text-with-border" target={"_blank"}>
                                                {lang == 'fr'?'Carrières':'careers'}
                                            </a>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4 hidden">
                                    <Link to="/orders"
                                          className="text-gray-900 hover:text-black px-3 py-2 rounded-md text-md font-medium"
                                    >
                                        My Account
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="hidden md:block">
                            <div className="ml-4 flex items-center md:ml-6">

                                {!menuRoute && !dashboardRoute ?
                                    <div className={"hidden lg:flex "}>
                                        <Link to={cart_items?.length > 0 && branch_guid ?"/menu":'/branch'}
                                              className="bg-black uppercase p-1 rounded-full text-white border-2 text-sm font-bold px-6 py-3 mr-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                              style={{color: "white !important"}}
                                        >
                                            <span className="text-white hover:text-white">
                                                {cart_items?.length > 0 ? <T>Continue Your Order</T> : <T>Start Order</T>}
                                            </span>
                                        </Link>

                                        <span className="mx-2 text-red-400 mt-1 text-small flex flex-col items-center justify-center">
                                          |
                                        </span>
                                    </div>
                                    : null}

                                {!dashboardRoute &&
                                    <>
                                        <LanguageBar/>
                                        <span className="mx-2 text-red-400 mt-1 text-small">
                                          |
                                        </span>
                                    </>
                                }



                                <Link to={!isLoggedIn ? "/login" : "/orders"} className={`bg-gray-100 text-black border-2 border-black rounded-full text-black border h-9  ${user?'pl-2 pr-3':'w-9'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white text-center flex items-center justify-center`}>
                                    <FontAwesomeIcon icon={faUserTie} className="text-2xl text-black hover:text-black" aria-hidden="true"/>
                                    {user &&
                                        <div className={"ml-2 font-bold text-black uppercase"}>
                                            {user.name}
                                        </div>
                                    }
                                </Link>

                                {isLoggedIn && user.type == "employee" ?
                                    <Link to={"/dashboard/index"} className="bg-gray-100 border-black rounded-full text-black border-2 w-9 h-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white text-center flex items-center justify-center" style={{marginLeft: "10px"}}>
                                        <FontAwesomeIcon icon={faTachometerAlt} className="text-2xl text-black hover:text-black" aria-hidden="true"/>
                                    </Link>
                                    : null}

                                {user && user.points_balance > 0 && false &&
                                    <Link to={"/club"} className={"flex flex-row px-3 py-1 text-white bg-black ml-2 rounded-lg font-bold border-2 border-white focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-black "}>
                                        <div className={"flex flex-row items-center text-white"}>
                                            <FontAwesomeIcon icon={faMedal} className="block text-sm mr-1" aria-hidden="true"/>
                                            {user.points_balance}
                                        </div>
                                    </Link>
                                }


                                    <>
                                        <span className="mx-2 text-red-400 mt-1 text-small">
                                          |
                                        </span>
                                        <div className="relative">
                                            <Link className="max-w-xs text-white hover:text-black rounded-full flex items-center text-sm focus:outline-none focus:ring-offset-gray-800 focus:ring-white z-50" to="/cart">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/bag.png'}
                                                    onClick={close}
                                                    className="w-11"
                                                />
                                            </Link>
                                            <CartBadge/>
                                        </div>
                                    </>
                            </div>
                        </div>
                        <div className="flex flex-row items-center md:hidden ">

                            <Link to={"/cart"} className={"text-black hover:text-black block relative"} onClick={close} style={{marginRight: "15px"}}>
                                <img
                                    src={process.env.PUBLIC_URL + '/images/bag.png'}
                                    onClick={close}
                                    className="h-10"
                                />
                                <CartBadge/>
                            </Link>

                            {isLoggedIn && (user.type == "employee" || user.type == "admin") ?
                                <Link
                                    to={"/dashboard/index"} className="bg-gray-100 border-black rounded-full text-black border-2 w-9 h-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white text-center flex items-center justify-center"
                                    onClick={dashboardIconClick}>
                                    <FontAwesomeIcon icon={faTachometerAlt} className="text-2xl text-black hover:text-black" aria-hidden="true"/>
                                </Link>
                                : null}
                        </div>

                    </div>
                </div>

                <AnimatePresence>
                    {!visible && (device_uuid) && false && (location.pathname.substring(0, 7) == "/order/" || location.pathname == "/" || location.pathname == "/menu") ?
                        <motion.div
                            initial={{opacity: 1, x: 500}}
                            animate={{opacity: 1, x: 0}}
                            exit={{opacity: 0, x: 500}}
                            style={{zIndex: 10}}
                            key={"active-orders-button"}
                        >
                            <div className="flex justify-end fixed right-0">
                                <div className="flex-shrink-0 px-2 uppercase text-black pr-2 bg-black bg-opacity-90 rounded-lg py-1 border-2 border-gray-300 mr-2 mt-2">
                                    <Link to={{pathname: "/orders/active"}} className="text-white text-xs flex flex-row items-start active:text-white hover:text-white w-32">
                                        <AnimatePresence>
                                            {v1_loading ?
                                                <motion.div
                                                    initial={{opacity: 0, scale: 2}}
                                                    animate={{opacity: 1, scale: 1}}
                                                    style={{zIndex: 10}}
                                                    key={"mobile-menu1"}
                                                >
                                                    <div className="flex flex-row items-center my-1 w-32 h-6 justify-center">
                                                        <SpinnerCircular size={20} color="rgba(255, 255, 255, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" className="mr-2 h-5"/>
                                                        <T>Loading</T>
                                                    </div>
                                                </motion.div>
                                                :
                                                <>
                                                    {total_active_orders > 0 ?
                                                        <>
                                                            <SpinnerRoundOutlined size={31} color="rgba(100, 255, 100,.7)" secondaryColor="rgba(0, 0, 0, 0.44)" className="mr-2"/>
                                                            <div className="flex flex-col items-start justify-center">
                                                                <div className="flex flex-row items-center text-xs font-bold">
                                                                    {total_active_orders} Order{total_active_orders > 1 ? 's' : null}
                                                                </div>
                                                                <div style={{fontSize: "8px"}} className="text-gray-300 lowercase">
                                                                    <span className="hidden">{total_active_orders > 1 ? 'are' : 'is'}</span> <T>being processed</T>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-xs px-2 py-1">
                                                            <T cap>No Active Orders</T>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </AnimatePresence>
                                    </Link>
                                </div>
                            </div>
                        </motion.div>
                        : null}
                </AnimatePresence>

                <AnimatePresence>
                    {visible ?
                        <motion.div
                            initial={{opacity: 1, y: -200}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 1, y: -500}}
                            style={{zIndex: 10001}}
                            key={"mobile-menu"}
                        >
                            <div className="md:hidden" ref={ref} style={{zIndex: 20001}}>
                                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-black uppercase">

                                    <div className="flex flex-row items-center absolute right-2">
                                        <LanguageBar/>
                                    </div>


                                    <Link to={"/branch"}
                                          className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 text-base font-medium flex flex-row items-center "
                                          onClick={close}
                                    >
                                        <FontAwesomeIcon icon={faShop} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                        <T upper>MENU</T>
                                    </Link>

                                    {!isLoggedIn ?
                                        <>
                                            {order_uids && order_uids.length > 0 ?
                                                <Link to={"/orders/offline"}
                                                      className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 text-base font-medium flex flex-row items-center"
                                                      onClick={close}
                                                >
                                                    <FontAwesomeIcon icon={faBurger} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                                    <T upper>MY ORDERS</T>
                                                </Link>
                                                : null}

                                            <Link to={"/login"}
                                                  className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 text-base font-medium flex flex-row items-center"
                                                  onClick={close}
                                            >
                                                <FontAwesomeIcon icon={faSigning} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                                <T upper>LOGIN</T>
                                            </Link>
                                        </>
                                        : null}

                                    {isLoggedIn && user ?
                                        <>
                                            {user.type == "employee" ?
                                                <Link to={"/dashboard/index"}
                                                      className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 text-base font-medium flex flex-row items-center"
                                                      onClick={close}
                                                >
                                                    <FontAwesomeIcon icon={faTachometerAlt} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                                    <T upper>DASHBOARD</T>
                                                </Link>
                                                : null}

                                            <Link to={"/orders"}
                                                  className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 text-base font-medium flex flex-row items-center"
                                                  onClick={close}
                                            >
                                                <FontAwesomeIcon icon={faUserTie} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                                <T upper>MY ACCOUNT</T>
                                            </Link>


                                            {false &&
                                                <Link to={"/club"}
                                                      className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 text-base font-medium flex flex-row items-center"
                                                      onClick={close}

                                                >
                                                    <div className={"flex flex-row mr-4 items-center"}>
                                                        <FontAwesomeIcon icon={faGem} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                                        <T upper>CLUB</T>
                                                    </div>

                                                    {user && user.points_balance > 0 &&
                                                        <div className={"flex flex-row px-2 pr-3 py-0 bg-red-brand rounded-lg font-bold"}>
                                                            <div className={"flex flex-row items-center"}>
                                                                <FontAwesomeIcon icon={faMedal} className="block text-sm mr-1" aria-hidden="true"/>
                                                                {user.points_balance}
                                                            </div>
                                                        </div>
                                                    }

                                                </Link>}

                                            <Link to={"/logout"}
                                                  className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium flex flex-row items-center"
                                                  onClick={close}
                                            >
                                                <FontAwesomeIcon icon={faSignOut} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                                <T upper>LOGOUT</T>
                                            </Link>
                                        </>
                                        : null}

                                    <>
                                        <Link to={"/about"}
                                              className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 text-base font-medium flex flex-row items-center "
                                              onClick={close}
                                        >
                                            <FontAwesomeIcon icon={faShop} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                            <T upper>ABOUT</T>
                                        </Link>


                                        <Link to={"/contact"}
                                              className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 text-base font-medium flex flex-row items-center "
                                              onClick={close}
                                        >
                                            <FontAwesomeIcon icon={faContactBook} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                            <T upper>CONTACT</T>
                                        </Link>


                                        <a href={"https://careers.pattyslaps.com/"} className="text-white uppercase font-bold text-with-border" target={"_blank"}
                                              className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 text-base font-medium flex flex-row items-center "
                                              onClick={close}
                                        >
                                            <FontAwesomeIcon icon={faFolder} className="block text-xl mr-4 w-4" aria-hidden="true"/>
                                            {lang == 'fr'?'Carrières':'careers'}
                                        </a>

                                    </>

                                </div>
                            </div>
                        </motion.div>
                        : null}
                </AnimatePresence>
            </>

        </div>);
};

export default Header;
