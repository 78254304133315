import React, { useEffect, useState } from 'react';
import { DatePicker, Card as AntCard, Row, Col, Button, Table, Input, Select } from 'antd';
import moment from 'moment';
import Coupon from "../../../../../http/Coupon";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import styled from 'styled-components';
import {
    DollarCircleOutlined,
    PercentageOutlined,
    TagOutlined,
    LineChartOutlined,
    FileExcelOutlined,
    TagsOutlined
} from '@ant-design/icons';
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../../../../components/skeletons/MenuSkeleton";
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const { RangePicker } = DatePicker;
const { Option } = Select;

// Custom styled component to remove border
const Card = styled(AntCard)`
  &.ant-card-bordered {
    border: none !important;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

// Styled component for count cards with bottom border
const CountCard = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  position: relative;
  border-bottom: 4px solid ${(props) => props.borderColor || '#e0e0e0'};
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor || '#e0e0e0'};
  margin-right: 16px;
`;

const CountValue = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const CountLabel = styled.div`
  font-size: 14px;
  color: #666;
`;

const CouponReports = () => {
    const { branchId } = useParams();
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [tempStartDate, setTempStartDate] = useState(startDate);
    const [tempEndDate, setTempEndDate] = useState(endDate);
    const [reportData, setReportData] = useState({
        total_sales: 0,
        discounts: [],
        total_transactions: 0,
        coupon_counts: { percent: 0, balance: 0 },
        discounts_per_day: [],
        discounts_per_hour: [],
        average_discounts: { balance: 0, percent: 0, all: 0 },
        all_discounts: [],
        discounts_by_employee: [],
        discounts_by_department: [],
        discounts_by_department_role: [],
        date_range: { start_date: '', end_date: '' },
    });

    // Pagination state for each table
    const [perPageDiscount, setPerPageDiscount] = useState(10); // For discount reports table (first table)
    const [perPageEmployee, setPerPageEmployee] = useState(10);
    const [perPageDepartment, setPerPageDepartment] = useState(10);
    const [perPageDepartmentRole, setPerPageDepartmentRole] = useState(10);

    const [searchEmployee, setSearchEmployee] = useState('');
    const [searchDepartment, setSearchDepartment] = useState('');
    const [searchDepartmentRole, setSearchDepartmentRole] = useState('');

    const fetchReports = () => {
        setLoading(true);
        const data = {
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
        };

        Coupon.Report(
            branchId,
            data,
            (response) => {
                const updatedDiscountsPerDay = response.data.data.discounts_per_day.map((day) => ({
                    ...day,
                    total_discounts: Math.round(day.total_discounts),
                    balance_discounts: Math.round(day.balance_discounts),
                    percent_discounts: Math.round(day.percent_discounts),
                }));

                setReportData({
                    ...response.data.data,
                    discounts_per_day: updatedDiscountsPerDay,
                });

                setLoading(false);
            },
            (err) => {
                console.error(err);
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        fetchReports();
    }, [startDate, endDate]);

    const handleDateChange = (dates) => {
        setTempStartDate(dates[0]);
        setTempEndDate(dates[1]);
    };

    const handleApply = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
    };

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(num);
    };

    const calculatePercentage = (value, total) => {
        if (total === 0) return 0;
        return ((value / total) * 100).toFixed(2);
    };

    const customLegendFormatter = (value) => {
        switch (value) {
            case 'total_discounts':
                return 'Total Discounts';
            case 'balance_discounts':
                return 'Balance Discounts';
            case 'percent_discounts':
                return 'Percent Discounts';
            default:
                return value;
        }
    };

    const customTooltipFormatter = (value) => `${formatNumber(value)}`;

    const handleExportToExcel = () => {
        const data = {
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
        };
        Coupon.ExportReportToExcel(
            branchId,
            reportData.branch_export_name,
            data,
            () => {
                console.log('Excel exported successfully.');
            },
            (err) => {
                console.error('Error exporting to Excel:', err);
            }
        );
    };

    const filterData = (data, searchTerm, key) => {
        return data.filter((item) =>
            item[key].toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    // Extract unique coupon_generate_preset_name values (handle null by labeling it as 'No Preset')
    const uniquePresetNames = [
        ...new Set(
            reportData.all_discounts.map(
                (discount) => discount.coupon_generate_preset_name || 'No Preset'
            )
        ),
    ];

    const [selectedPresetName, setSelectedPresetName] = useState(''); // State to manage selected preset name

    // Filtering logic for coupon_generate_preset_name
    const filteredDiscountData = reportData.all_discounts.filter((discount) => {
        const presetName = discount.coupon_generate_preset_name || 'No Preset';
        return selectedPresetName === '' || presetName === selectedPresetName;
    });

    // Table columns definition for the discount reports table
    const discountColumns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Coupon Type',
            dataIndex: 'coupon_type',
            key: 'coupon_type',
            render: (type) => type.charAt(0).toUpperCase() + type.slice(1),
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (type) => type.charAt(0).toUpperCase() + type.slice(1),
        },
        {
            title: 'Discount Preset Name',
            dataIndex: 'discount_preset_name',
            key: 'discount_preset_name',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Coupon Generate Preset Name',
            dataIndex: 'coupon_generate_preset_name',
            key: 'coupon_generate_preset_name',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (total) => `$${total.toFixed(2)}`,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
        },
    ];

    const employeeColumns = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Total Discounts',
            dataIndex: 'total_discounts',
            key: 'total_discounts',
            render: (text) => formatNumber(text),
        },
        {
            title: 'Discount Count',
            dataIndex: 'discount_count',
            key: 'discount_count',
        },
        {
            title: 'Coupon Presets',
            dataIndex: 'coupon_presets',
            key: 'coupon_presets',
            render: (presets) =>
                presets.map((preset, index) => (
                    <div key={index}>
                        <b>{preset.preset_name}</b>: {formatNumber(preset.preset_total)} ({preset.preset_count}{' '}
                        <TagsOutlined />)
                    </div>
                )),
        },
    ];

    const departmentColumns = [
        {
            title: 'Department Name',
            dataIndex: 'department_name',
            key: 'department_name',
        },
        {
            title: 'Total Discounts',
            dataIndex: 'total_discounts',
            key: 'total_discounts',
            render: (text) => formatNumber(text),
        },
        {
            title: 'Discount Count',
            dataIndex: 'discount_count',
            key: 'discount_count',
        },
        {
            title: 'Coupon Presets',
            dataIndex: 'coupon_presets',
            key: 'coupon_presets',
            render: (presets) =>
                presets.map((preset, index) => (
                    <div key={index}>
                        <b>{preset.preset_name}</b>: {formatNumber(preset.preset_total)} ({preset.preset_count}{' '}
                        <TagsOutlined />)
                    </div>
                )),
        },
    ];

    const departmentRoleColumns = [
        {
            title: 'Department Role Name',
            dataIndex: 'department_role_name',
            key: 'department_role_name',
        },
        {
            title: 'Total Discounts',
            dataIndex: 'total_discounts',
            key: 'total_discounts',
            render: (text) => formatNumber(text),
        },
        {
            title: 'Discount Count',
            dataIndex: 'discount_count',
            key: 'discount_count',
        },
        {
            title: 'Coupon Presets',
            dataIndex: 'coupon_presets',
            key: 'coupon_presets',
            render: (presets) =>
                presets.map((preset, index) => (
                    <div key={index}>
                        <b>{preset.preset_name}</b>: {formatNumber(preset.preset_total)} ({preset.preset_count}{' '}
                        <TagsOutlined />)
                    </div>
                )),
        },
    ];

    // Calculate total discounts (for the analysis section)
    const totalDiscounts = reportData.discounts.reduce(
        (acc, discount) => acc + discount.total_redeemed,
        0
    );

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.1, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            className="w-full less-margin mx-auto max-w-8xl mt-2"
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card>
                        <div className="patty text-2xl font-bold">
                            Discount Reports {reportData && <>- {reportData.branch_name}</>}
                        </div>

                        <p className="mb-2 text-gray-600">
                            This report provides an analysis of total sales and discounts applied over a specified date range.
                            Use the date picker below to select the desired date range for the report.
                        </p>
                        <div className="flex items-center justify-between flex-wrap">
                            <div className="flex items-center flex-wrap">
                                <RangePicker
                                    value={[tempStartDate, tempEndDate]}
                                    onChange={handleDateChange}
                                    className="mr-4"
                                />
                                <Button type="primary" onClick={handleApply}>
                                    Apply
                                </Button>
                            </div>
                        </div>

                        {reportData?.date_range?.start_date && (
                            <div className="flex flex-row items-center space-x-4 border-t border-gray-100 w-full justify-end pr-2 pt-2 mt-3">
                                <div className="text-gray-600">
                                    Showing data for {reportData.branch_name}:{' '}
                                    <strong>{moment(reportData.date_range.start_date).format('MMMM D, YYYY')}</strong> to{' '}
                                    <strong>{moment(reportData.date_range.end_date).format('MMMM D, YYYY')}</strong>
                                </div>
                                <Button icon={<FileExcelOutlined />} onClick={handleExportToExcel}>
                                    Export to Excel
                                </Button>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>

            <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
                {/* Analysis Cards */}
                <Row gutter={[16, 16]} className="mt-4 px-1">
                    <Col xs={24} sm={12} md={8}>
                        <CountCard borderColor="#1890ff">
                            <IconWrapper bgColor="#d3eafd">
                                <DollarCircleOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                            </IconWrapper>
                            <div>
                                <CountValue>{reportData.total_transactions}</CountValue>
                                <CountLabel>Total Sales Count</CountLabel>
                            </div>
                        </CountCard>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <CountCard borderColor="#3dbb14">
                            <IconWrapper bgColor="#d0efc5">
                                <TagOutlined style={{ fontSize: '24px', color: '#3dbb14' }} />
                            </IconWrapper>
                            <div>
                                <CountValue>{reportData.coupon_counts.balance}</CountValue>
                                <CountLabel>Total Balance Coupons Count</CountLabel>
                            </div>
                        </CountCard>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <CountCard borderColor="#fa8c16">
                            <IconWrapper bgColor="#ffe7ba">
                                <PercentageOutlined style={{ fontSize: '24px', color: '#fa8c16' }} />
                            </IconWrapper>
                            <div>
                                <CountValue>{reportData.coupon_counts.percent}</CountValue>
                                <CountLabel>Total Percent Coupons Count</CountLabel>
                            </div>
                        </CountCard>
                    </Col>
                </Row>

                {/* Analysis Charts */}
                <Row gutter={[16, 16]} className="mt-1">
                    <Col xs={24} md={12}>
                        <Card title="Discounts and Sales Analysis" style={{ height: '350px' }}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="flex flex-col space-y-4 p-3 pt-2">
                                        <div>
                                            <div className="flex items-center justify-between">
                                                <span>Sales</span>
                                                <span className="font-bold">{formatNumber(reportData.total_sales)}</span>
                                            </div>
                                            <div className="relative w-full h-6 rounded-r-lg bg-gray-200">
                                                <div className="absolute h-full rounded-r-lg bg-blue-500" style={{ width: '100%' }}></div>
                                            </div>
                                        </div>

                                        {/* Total Discounts */}
                                        <div>
                                            <div className="flex items-center justify-between">
                                                <span>Total Discounts</span>
                                                <span>
                          <b>{formatNumber(totalDiscounts)}</b> ({calculatePercentage(totalDiscounts, reportData.total_sales)}%)
                        </span>
                                            </div>
                                            <div className="relative w-full h-6 rounded-r-lg bg-gray-200">
                                                <div
                                                    className="absolute h-full rounded-r-lg bg-orange-500"
                                                    style={{
                                                        width: `${calculatePercentage(totalDiscounts, reportData.total_sales)}%`,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>

                                        {reportData.discounts.map((discount, index) => (
                                            <div key={index}>
                                                <div className="flex items-center justify-between">
                          <span>
                            {discount.coupon_type.charAt(0).toUpperCase() +
                                discount.coupon_type.slice(1)}{' '}
                              Discounts
                          </span>
                                                    <span>
                            <b>{formatNumber(discount.total_redeemed)}</b> (
                                                        {calculatePercentage(discount.total_redeemed, reportData.total_sales)}%)
                          </span>
                                                </div>
                                                <div className="relative w-full h-6 rounded-r-lg bg-gray-200">
                                                    <div
                                                        className="absolute h-full rounded-r-lg"
                                                        style={{
                                                            width: `${calculatePercentage(discount.total_redeemed, reportData.total_sales)}%`,
                                                            backgroundColor:
                                                                discount.coupon_type === 'percent' ? '#fa8c16' : '#3dbb14',
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card title="Discounts Analysis by Day of the Week" style={{ height: '350px' }}>
                            <ResponsiveContainer width="100%" height={275}>
                                <ComposedChart data={reportData.discounts_per_day}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="day_of_week" />
                                    <YAxis tickFormatter={customTooltipFormatter} />
                                    <Tooltip formatter={customTooltipFormatter} />
                                    <Legend formatter={customLegendFormatter} />
                                    <Bar dataKey="total_discounts" fill="#fa8c16" barSize={20} />
                                    <Line dataKey="balance_discounts" stroke="#3dbb14" strokeWidth={2} />
                                    <Line dataKey="percent_discounts" stroke="#8884d8" strokeWidth={2} />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="mt-4">
                    <Col xs={24} lg={17}>
                        <Card title="Discounts Usage by Hour" style={{ height: '350px' }}>
                            <ResponsiveContainer width="100%" height={275}>
                                <ComposedChart data={reportData.discounts_per_hour}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="hour" />
                                    <YAxis tickFormatter={customTooltipFormatter} />
                                    <Tooltip formatter={customTooltipFormatter} />
                                    <Legend formatter={customLegendFormatter} />
                                    <Bar dataKey="total_discounts" fill="#1890ff" barSize={20} />
                                    <Line dataKey="balance_discounts" stroke="#3dbb14" strokeWidth={2} />
                                    <Line dataKey="percent_discounts" stroke="#8884d8" strokeWidth={2} />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Card>
                    </Col>

                    {/* Average Discount Values */}
                    <Col xs={24} lg={7}>
                        <Card title="Average Discount Value by Type" style={{ height: '350px' }}>
                            <div className="flex flex-col justify-center p-2 pt-4">
                                <div className="flex items-center pl-1 border-b pb-3 mb-3">
                                    <IconWrapper bgColor="#d0efc5">
                                        <TagOutlined style={{ fontSize: '30px', color: '#3dbb14' }} />
                                    </IconWrapper>
                                    <div className="flex flex-col">
                                        <CountLabel>Average Balance Discount Value</CountLabel>
                                        <CountValue>{formatNumber(reportData.average_discounts.balance)}</CountValue>
                                    </div>
                                </div>
                                <div className="flex items-center pl-1 border-b pb-3 mb-3">
                                    <IconWrapper bgColor="#ffe7ba">
                                        <PercentageOutlined style={{ fontSize: '30px', color: '#fa8c16' }} />
                                    </IconWrapper>
                                    <div className="flex flex-col">
                                        <CountLabel>Average Percent Discount Value</CountLabel>
                                        <CountValue>{formatNumber(reportData.average_discounts.percent)}</CountValue>
                                    </div>
                                </div>
                                <div className="flex items-center pl-1">
                                    <IconWrapper bgColor="#e0e0e0">
                                        <LineChartOutlined style={{ fontSize: '30px', color: '#000000' }} />
                                    </IconWrapper>
                                    <div className="flex flex-col">
                                        <CountLabel>Average of All Discounts</CountLabel>
                                        <CountValue>{formatNumber(reportData.average_discounts.all)}</CountValue>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>

                {/* Discount Reports Table (First Table) */}
                <Row gutter={[16, 16]} className="mt-4">
                    <Col span={24}>
                        <Card>
                            <div className="patty text-2xl font-bold">Discount Reports</div>
                            <p className="mb-2 text-gray-600">
                                You can filter the discounts by selecting a coupon generate preset name from the dropdown.
                            </p>

                            {/* Dropdown for selecting coupon_generate_preset_name */}
                            <Select
                                placeholder="Select a Coupon Generate Preset Name"
                                onChange={(value) => setSelectedPresetName(value)}
                                value={selectedPresetName}
                                style={{ width: 300, marginBottom: 16 }}
                                allowClear
                            >
                                <Option value="">All Presets</Option>
                                {uniquePresetNames.map((presetName, index) => (
                                    <Option key={index} value={presetName}>
                                        {presetName}
                                    </Option>
                                ))}
                            </Select>

                            <Table
                                columns={discountColumns}
                                dataSource={filteredDiscountData}
                                // Use a function for rowKey to ensure a unique key even if "id" is missing.
                                rowKey={(record, index) => record.id || index}
                                pagination={{
                                    pageSize: perPageDiscount,
                                    showSizeChanger: true,
                                    pageSizeOptions: ['10', '20', '30', '50'],
                                    onShowSizeChange: (current, size) => setPerPageDiscount(size),
                                }}
                                bordered
                            />
                        </Card>
                    </Col>
                </Row>

                {/* Discounts by Employee Table */}
                <Row gutter={[16, 16]} className="mt-4">
                    <Col span={24}>
                        <Card title="Discounts by Employee" style={{ height: 'auto' }}>
                            <Input.Search
                                placeholder="Search Employee"
                                value={searchEmployee}
                                onChange={(e) => setSearchEmployee(e.target.value)}
                                style={{ marginBottom: 16, maxWidth: 300 }}
                            />
                            <Table
                                columns={employeeColumns}
                                dataSource={filterData(reportData.discounts_by_employee, searchEmployee, 'employee_name')}
                                rowKey="employee_name"
                                pagination={{
                                    pageSize: perPageEmployee,
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10', '20', '30', '50'],
                                    onShowSizeChange: (current, size) => setPerPageEmployee(size),
                                }}
                                bordered
                            />
                        </Card>
                    </Col>
                </Row>

                {/* Discounts by Department Table */}
                <Row gutter={[16, 16]} className="mt-4">
                    <Col span={24}>
                        <Card title="Discounts by Department" style={{ height: 'auto' }}>
                            <Input.Search
                                placeholder="Search Department"
                                value={searchDepartment}
                                onChange={(e) => setSearchDepartment(e.target.value)}
                                style={{ marginBottom: 16, maxWidth: 300 }}
                            />
                            <Table
                                columns={departmentColumns}
                                dataSource={filterData(reportData.discounts_by_department, searchDepartment, 'department_name')}
                                rowKey="department_name"
                                pagination={{
                                    pageSize: perPageDepartment,
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10', '20', '30', '50'],
                                    onShowSizeChange: (current, size) => setPerPageDepartment(size),
                                }}
                                bordered
                            />
                        </Card>
                    </Col>
                </Row>

                {/* Discounts by Department Role Table */}
                <Row gutter={[16, 16]} className="mt-4">
                    <Col span={24}>
                        <Card title="Discounts by Department Role" style={{ height: 'auto' }}>
                            <Input.Search
                                placeholder="Search Department Role"
                                value={searchDepartmentRole}
                                onChange={(e) => setSearchDepartmentRole(e.target.value)}
                                style={{ marginBottom: 16, maxWidth: 300 }}
                            />
                            <Table
                                columns={departmentRoleColumns}
                                dataSource={filterData(reportData.discounts_by_department_role, searchDepartmentRole, 'department_role_name')}
                                rowKey="department_role_name"
                                pagination={{
                                    pageSize: perPageDepartmentRole,
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10', '20', '30', '50'],
                                    onShowSizeChange: (current, size) => setPerPageDepartmentRole(size),
                                }}
                                bordered
                            />
                        </Card>
                    </Col>
                </Row>
            </SkeletonWrapper>
        </motion.div>
    );
};

export default CouponReports;
